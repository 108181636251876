import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, KeepAlive as _KeepAlive, normalizeClass as _normalizeClass, withCtx as _withCtx, createSlots as _createSlots, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-596a77b9"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "title-row"
}
const _hoisted_2 = { class: "template-title" }
const _hoisted_3 = { class: "right-area" }
const _hoisted_4 = {
  class: "main-contain",
  ref: "templateMainContain"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_BreadScrum = _resolveComponent("BreadScrum")!
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_HeaderTitleBar = _resolveComponent("HeaderTitleBar")!
  const _component_flat_button = _resolveComponent("flat-button")!
  const _component_StepProgressBar = _resolveComponent("StepProgressBar")!
  const _component_EditBottomNavBar = _resolveComponent("EditBottomNavBar")!
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_AfterLoginPageLayout = _resolveComponent("AfterLoginPageLayout")!
  const _component_ConfirmDeletePopup = _resolveComponent("ConfirmDeletePopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.$route.name == 'TemplateList')
      ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
      : (_openBlock(), _createBlock(_component_AfterLoginPageLayout, {
          key: 1,
          class: "template",
          "is-sub-header-menu": true
        }, _createSlots({
          title: _withCtx(() => [
            (_ctx.isEditing)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_BreadScrum, {
                    paths: _ctx.breadScrumPaths,
                    class: "breadscrum-grey"
                  }, null, 8, ["paths"]),
                  _createVNode(_component_TextButton, {
                    text: _ctx.$t('contractSigner.deleteButton'),
                    icon: require('icons/delete.svg'),
                    class: "danger-btn ml-2",
                    onOnClick: _ctx.handleDeleteTemplate
                  }, null, 8, ["text", "icon", "onOnClick"])
                ]))
              : (_openBlock(), _createBlock(_component_HeaderTitleBar, {
                  key: 1,
                  title: _ctx.$t('template.addButton'),
                  "delete-button-text": 
          _ctx.step == _ctx.lastStep ? '' : _ctx.$t('contractSigner.deleteButton')
        ,
                  onDelete: _ctx.discardTempContract
                }, null, 8, ["title", "delete-button-text", "onDelete"]))
          ]),
          bottomRight: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.isEditing && !_ctx.isMobile)
                ? (_openBlock(), _createBlock(_component_flat_button, {
                    key: 0,
                    text: _ctx.$t('common.saveChanges'),
                    enabled: _ctx.files.length > 0 && _ctx.contractBasicInfo.title != '',
                    class: "w-full",
                    onClick: _ctx.saveEditTemplate
                  }, null, 8, ["text", "enabled", "onClick"]))
                : _createCommentVNode("", true),
              _createVNode(_component_StepProgressBar, {
                items: _ctx.progressItems,
                "current-index": _ctx.step,
                "is-edit": _ctx.isEditing,
                "error-index": _ctx.errorIndex
              }, null, 8, ["items", "current-index", "is-edit", "error-index"])
            ])
          ]),
          bottomLeft: _withCtx(({ detectScrollbar }) => [
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(), _createBlock(_KeepAlive, null, [
                (_ctx.isMounted)
                  ? (_openBlock(), _createBlock(_component_router_view, {
                      key: 0,
                      files: _ctx.files,
                      "onUpdate:files": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.files) = $event)),
                      contractBasicInfo: _ctx.contractBasicInfo,
                      "onUpdate:contractBasicInfo": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.contractBasicInfo) = $event)),
                      contractDetailInfo: _ctx.contractDetailInfo,
                      "onUpdate:contractDetailInfo": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.contractDetailInfo) = $event)),
                      securitiesInfo: _ctx.securitiesInfo,
                      "onUpdate:securitiesInfo": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.securitiesInfo) = $event)),
                      signers: _ctx.signers,
                      "onUpdate:signers": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.signers) = $event)),
                      suggestions: _ctx.suggestTexts,
                      step: _ctx.step,
                      "is-editing": _ctx.isEditing,
                      "folder-id": _ctx.folderId,
                      "detect-scrollbar": detectScrollbar,
                      isDocxTemplate: _ctx.isDocxTemplate,
                      "onUpdate:isDocxTemplate": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isDocxTemplate) = $event)),
                      viewers: _ctx.viewers,
                      "onUpdate:viewers": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.viewers) = $event)),
                      companyInfo: _ctx.companyInfo
                    }, null, 8, ["files", "contractBasicInfo", "contractDetailInfo", "securitiesInfo", "signers", "suggestions", "step", "is-editing", "folder-id", "detect-scrollbar", "isDocxTemplate", "viewers", "companyInfo"]))
                  : _createCommentVNode("", true)
              ], 1024)),
              (_ctx.isMobile && _ctx.isEditing)
                ? (_openBlock(), _createBlock(_component_EditBottomNavBar, {
                    key: 0,
                    "on-back": _ctx.step >= 1 ? _ctx.back : null,
                    "on-next": 
            _ctx.step != _ctx.lastStep &&
            !(_ctx.isEditing && _ctx.step == _ctx.lastStep - 1) &&
            _ctx.files.length > 0 &&
            _ctx.contractBasicInfo.title != ''
              ? _ctx.next
              : null
          ,
                    "on-edit": 
            _ctx.files.length > 0 && _ctx.contractBasicInfo.title != ''
              ? _ctx.saveEditTemplate
              : null
          
                  }, null, 8, ["on-back", "on-next", "on-edit"]))
                : (!_ctx.isMobile)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: _normalizeClass(["button-group", { 'no-content': _ctx.step == _ctx.lastStep }])
                    }, [
                      (_ctx.step != 0 && _ctx.step != _ctx.lastStep)
                        ? (_openBlock(), _createBlock(_component_FlatButton, {
                            key: 0,
                            class: "transition-button pre-btn",
                            text: _ctx.$t('templateSigner.backButton'),
                            icon: require('icons/prev-arrow-white.svg'),
                            onOnClick: _ctx.back
                          }, null, 8, ["text", "icon", "onOnClick"]))
                        : _createCommentVNode("", true),
                      (_ctx.step != _ctx.lastStep && !(_ctx.isEditing && _ctx.step == _ctx.lastStep - 1))
                        ? (_openBlock(), _createBlock(_component_FlatButton, {
                            key: 1,
                            class: "transition-button next-btn",
                            text: _ctx.$t('common.next'),
                            "right-icon": 
              _ctx.files.length > 0 && _ctx.contractBasicInfo.title != ''
                ? require('icons/next-arrow-white.svg')
                : require('icons/next-arrow-grey.svg')
            ,
                            enabled: _ctx.files.length > 0 && _ctx.contractBasicInfo.title != '',
                            onOnClick: _ctx.next
                          }, null, 8, ["text", "right-icon", "enabled", "onOnClick"]))
                        : _createCommentVNode("", true)
                    ], 2))
                  : (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      class: _normalizeClass(["button-group", { 'no-content': _ctx.step == _ctx.lastStep }])
                    }, [
                      (_ctx.step != 0 && _ctx.step != _ctx.lastStep)
                        ? (_openBlock(), _createBlock(_component_TextButton, {
                            key: 0,
                            icon: require('icons/prev-arrow.svg'),
                            text: _ctx.$t('templateSigner.backButton'),
                            onOnClick: _ctx.back,
                            class: "pre-btn"
                          }, null, 8, ["icon", "text", "onOnClick"]))
                        : _createCommentVNode("", true),
                      (_ctx.step != _ctx.lastStep && !(_ctx.isEditing && _ctx.step == _ctx.lastStep - 1))
                        ? (_openBlock(), _createBlock(_component_TextButton, {
                            key: 1,
                            text: _ctx.$t('onboarding.submitText'),
                            "right-icon": require('icons/next-arrow.svg'),
                            "disabled-icon": require('icons/next-arrow-grey.svg'),
                            enabled: _ctx.files.length > 0 && _ctx.contractBasicInfo.title != '',
                            class: "next-btn",
                            onOnClick: _ctx.next
                          }, null, 8, ["text", "right-icon", "disabled-icon", "enabled", "onOnClick"]))
                        : _createCommentVNode("", true)
                    ], 2))
            ], 512)
          ]),
          _: 2
        }, [
          (_ctx.isMobile || _ctx.step === 2)
            ? {
                name: "topLeft",
                fn: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createTextVNode(_toDisplayString(_ctx.progressItems[_ctx.step].name) + " ", 1),
                    _createElementVNode("span", null, _toDisplayString(`(${_ctx.step + 1}/${_ctx.progressItems.length})`), 1)
                  ])
                ]),
                key: "0"
              }
            : undefined
        ]), 1024)),
    _createVNode(_component_ConfirmDeletePopup, {
      id: _ctx.contractBasicInfo?.id ?? '',
      title: _ctx.contractBasicInfo.title,
      onOnSubmit: _ctx.onDeleteContract
    }, null, 8, ["id", "title", "onOnSubmit"])
  ], 64))
}