
import { defineComponent, computed, reactive, toRefs } from "vue";

import FlatButton from "@/components/atomics/FlatButton.vue";
import Popup from "@/components/popups/Popup.vue";
import { mapMutations, useStore } from "vuex";
import { ContractRepository, RepositoryFactory } from "@/lib/https";

export default defineComponent({
  name: "TemplateConfirmDelete",
  components: {
    FlatButton,
    Popup,
  },
  props: {
    title: { type: String, required: true },
    id: { type: String, required: true },
  },
  emits: ["onSubmit"],
  setup(props, context) {
    const state = reactive({
      isLoading: false,
    });

    const { deleteContract } =
      RepositoryFactory.getRepository<ContractRepository>(ContractRepository);
    const store = useStore();

    const confirm = async () => {
      await deleteContract(props.id)
        .then(async (res) => {
          context.emit("onSubmit", { res });
        })
        .catch((err) => {
          context.emit("onSubmit", { err });
        });
      store.commit("popup/unsetPopup", "TemplateConfirmDelete");
    };

    return {
      ...toRefs(state),
      confirm,
      popupState: computed(() => store.state.popup.TemplateConfirmDelete),
    };
  },
  methods: {
    ...mapMutations("popup", ["setPopup", "unsetPopup"]),
    hidePopup(): void {
      this.unsetPopup("TemplateConfirmDelete");
    },
  },
});
/**
 * @vuese
 * @group Layout
 * Popup layout
 */
