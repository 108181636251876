
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  computed,
  onBeforeMount,
  watch,
  inject,
  onMounted,
  onBeforeUnmount,
} from "vue";
import TextButton from "@/components/atomics/TextButton.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import StepProgressBar from "@/components/atomics/StepProgressBar.vue";
import HeaderTitleBar from "@/components/parts/HeaderTitleBar.vue";
import { useI18n } from "vue-i18n";
import { FileInfo } from "@/models/contracts/FileInfo";
import { FileInput } from "@/models/contracts/FileInput";

import { ContractViewer } from "@/models/contracts/ContractViewer";

import Signer from "@/models/Signer";
import AfterLoginPageLayout from "@/views/layouts/AfterLoginPageLayout.vue";
import { useRoute, useRouter } from "vue-router";
import {
  CompanyRepository,
  FolderRepository,
  RepositoryFactory,
  TemplateRepository,
} from "@/lib/https";
import BreadScrum from "@/components/atomics/BreadScrum.vue";
import EditBottomNavBar from "@/components/organisms/EditBottomNavBar.vue";
import ConfirmDeletePopup from "@/components/popups/templates/TemplateConfirmDeletePopup.vue";
import { formatLocalDate, getListFolderFromPath } from "@/lib/utility/common";
import { mapMutations, useStore } from "vuex";
import { TemplateInformation } from "@/models/contracts/Information";
import useMapAndStore from "@/lib/compositional-logic/useMapAndStore";

export default defineComponent({
  name: "Template",
  components: {
    AfterLoginPageLayout,
    TextButton,
    FlatButton,
    StepProgressBar,
    HeaderTitleBar,
    EditBottomNavBar,
    BreadScrum,
    ConfirmDeletePopup,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const i18n = useI18n();
    const isMobile = inject("isMobile");
    const store = useStore();

    const { parseContractDate } = useMapAndStore();

    const { getListFolder } =
      RepositoryFactory.getRepository<FolderRepository>(FolderRepository);
    const { getCompany } =
      RepositoryFactory.getRepository<CompanyRepository>(CompanyRepository);

    const state = reactive({
      isDocxTemplate: false,
      breadScrumPaths: [] as {
        text: string;
        to: string;
      }[],
      step: 0,
      folderId: route.query.folderId,
      isMounted: false,
      companyInfo: {} as any,
    });

    const errorIndex = computed(() => {
      if (!contractBasicInfo.title || files.value.length < 1) return 0;
      return -1;
    });

    const isEditing = computed(() => (route.params.id ? true : false));

    const lastStep = computed(() => (state.isDocxTemplate ? 1 : 4));

    const progressItems = computed(() =>
      state.isDocxTemplate
        ? [
            {
              name: i18n.t("template.progressAddFile"),
              event: () => (state.step = 0),
            },
            { name: i18n.t("template.progressDone") },
          ]
        : [
            {
              name: i18n.t("template.progressAddFile"),
              event: () => (state.step = 0),
            },
            {
              name: i18n.t("template.progressSigner"),
              event: () => (state.step = 1),
            },
            {
              name: i18n.t("template.progressVars"),
              event: () => (state.step = 2),
            },
            {
              name: i18n.t("template.progressConfirm"),
              event: () => (state.step = 3),
            },
            ...(isEditing.value
              ? []
              : [{ name: i18n.t("template.progressDone") }]),
          ]
    );

    watch(
      () => state.step,
      (value, oldValue) => {
        let routeName = "TemplateList";
        if (value == 0 && oldValue == 1) routeName = "TemplateFileAndInfo";
        if (value == 1) {
          if (state.isDocxTemplate) {
            const { createContracts } =
              RepositoryFactory.getRepository<TemplateRepository>(
                TemplateRepository
              );
            const data = {
              folderId: state.folderId,
              isTemplate: true,
              isDocxTemplate: true,
              title: contractBasicInfo.title,
              contractFiles: [] as { order: number }[],
            };
            const formData = new FormData();
            files.value.forEach((file, index) => {
              formData.append(
                `file${index + 1}`,
                new File([file.data], file.name, {
                  type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                })
              );
              data.contractFiles.push({ order: index + 1 });
            });
            formData.append("data", JSON.stringify(data));
            createContracts(formData).then((res) => {
              if (res)
                router.push({
                  name: "TemplateDone",
                  params: {
                    folderId: data.folderId as string,
                    folderName: route.query.folderName as string,
                  },
                });
            });
            return;
          }
          routeName = "TemplateSigner";
        }
        if (value == 2) routeName = "TemplateVars";
        if (value == 3) routeName = "TemplateSummary";
        if (value == 4) {
          const formData = new FormData();
          files.value.forEach((file, index) => {
            formData.append(`file${index + 1}`, file.data);
          });

          const templateInformation =
            TemplateInformation.prototype.toJson.call(contractDetailInfo);

          const templateJson = JSON.stringify({
            folderId: state.folderId,
            isTemplate: true,
            title: contractBasicInfo.title,
            contractPartnerName: contractBasicInfo.contractPartnerName,
            isXIDRequired: securitiesInfo.isXIDRequired,
            isOnlyPartnerXIDRequired: securitiesInfo.isOnlyPartnerXIDRequired,
            is2FARequired: securitiesInfo.is2FARequired,
            isSignerTransferAllowed: securitiesInfo.isSignerTransferAllowed,
            needTimeStamp: securitiesInfo.needTimeStamp,
            contractInformation: templateInformation,
            contractSigners: signers.value.map((signer, index) =>
              signer.id
                ? {
                    order: index + 1,
                    userId: signer.id,
                    SignerEmail: signer.email,
                    SignerLastName: signer.lastName,
                    SignerFirstName: signer.firstName,
                    SignerCompanyName: signer.companyName,
                    SignerAccessCode: signer.accessCode || "",
                  }
                : {
                    order: index + 1,
                    placeHolder: signer.placeholder,
                    SignerEmail: signer.email,
                    SignerLastName: signer.lastName,
                    SignerFirstName: signer.firstName,
                    SignerCompanyName: signer.companyName,
                  }
            ),
            contractViewers: viewers.value.map((viewer, index) => ({
              order: index + 1,
              userId: viewer.id,
              ViewerEmail: viewer.email,
              ViewerLastName: viewer.lastName,
              ViewerFirstName: viewer.firstName,
              ViewerCompanyName: viewer.companyName,
              ViewerAccessCode: viewer.accessCode || "",
            })),
            contractFiles: files.value.map((file: any, index) => {
              return {
                order: index + 1,
                contractVars: file.inputs.map((i: FileInput) => {
                  const scale = file.scale ?? 1.0;
                  return i.toJson(scale);
                }),
              };
            }),
          });

          formData.append("data", templateJson);

          const { createContracts } =
            RepositoryFactory.getRepository<TemplateRepository>(
              TemplateRepository
            );

          createContracts(formData).then((res) => {
            if (res)
              router.push({
                name: "TemplateDone",
                params: {
                  templateId: res.id,
                  folderId: route.query.folderId as string,
                  folderName: route.query.folderName as string,
                },
              });
          })
          .catch(async (err) => {
            state.step = 3;
            store.commit("notification/showErrorNotification", err.data.message);
          });
          return;
        }

        if (isEditing.value)
          router.push({
            name: routeName,
            params: { id: route.params.id },
          });
        else router.push({ name: routeName });
      }
    );

    const files = ref([] as Array<FileInfo>);

    const contractBasicInfo = reactive({
      id: null as null | string,
      title: "",
      contractPartnerName: "",
    });

    const contractDetailInfo = reactive({
      contractOverview: "",
      contractConclusionDate: null as Date | null,
      contractStartDate: null as Date | null,
      contractEndDate: null as Date | null,
      terminationNoticePeriod: "",
      isAutomaticallyUpdated: "null",
      controlNumber: "",
      transactionAmount: 0,
      freeText: "",
    });

    const signers = ref([] as Signer[]);

    const securitiesInfo = reactive({
      isXIDRequired: false,
      isOnlyPartnerXIDRequired: false,
      is2FARequired: false,
      isSignerTransferAllowed: true,
      needTimeStamp: true,
    });

    const viewers = ref([] as ContractViewer[]);

    const suggestTexts = computed(() => {
      return [
        contractDetailInfo.contractOverview,
        contractDetailInfo.contractConclusionDate
          ? formatLocalDate(
              contractDetailInfo.contractConclusionDate,
              i18n.t("contractSummary.dateFormat")
            )
          : "",
        contractDetailInfo.contractStartDate
          ? formatLocalDate(
              contractDetailInfo.contractStartDate,
              i18n.t("contractSummary.dateFormat")
            )
          : "",
        contractDetailInfo.contractEndDate
          ? formatLocalDate(
              contractDetailInfo.contractEndDate,
              i18n.t("contractSummary.dateFormat")
            )
          : "",
        contractDetailInfo.terminationNoticePeriod,
        contractDetailInfo.isAutomaticallyUpdated == "true"
          ? i18n.t("template.updateOption1")
          : contractDetailInfo.isAutomaticallyUpdated == "false"
          ? i18n.t("template.updateOption2")
          : "",
        contractDetailInfo.controlNumber,
        contractDetailInfo.transactionAmount.toString(),
        contractDetailInfo.freeText,
      ].filter((e) => e !== "");
    });

    onBeforeMount(async () => {
      state.companyInfo = await getCompany();

      if (isEditing.value) {
        const { getContractsFullInformation } =
          RepositoryFactory.getRepository<TemplateRepository>(
            TemplateRepository
          );
        const templateDetail = await getContractsFullInformation(
          route.params.id as string
        );
        if (templateDetail) {
          const { id, title, contractPartnerName, folderId } = templateDetail;
          contractBasicInfo.id = id;
          contractBasicInfo.title = title;
          contractBasicInfo.contractPartnerName = contractPartnerName;
          state.folderId = folderId || "";

          const {
            contractOverview = "",
            contractConclusionDate,
            contractStartDate,
            contractEndDate,
            isAutomaticallyUpdated = "null",
            controlNumber = "",
            freeText = "",
            terminationNoticePeriod = "",
            transactionAmount = 0,
          } = templateDetail.contractInformation ?? {};
          contractDetailInfo.contractOverview = contractOverview;
          contractDetailInfo.contractConclusionDate = parseContractDate(
            contractConclusionDate
          );
          contractDetailInfo.contractEndDate =
            parseContractDate(contractEndDate);
          contractDetailInfo.contractStartDate =
            parseContractDate(contractStartDate);
          contractDetailInfo.isAutomaticallyUpdated =
            isAutomaticallyUpdated === true
              ? "true"
              : isAutomaticallyUpdated === false
              ? "false"
              : "null";
          contractDetailInfo.controlNumber = controlNumber;
          contractDetailInfo.freeText = freeText;
          contractDetailInfo.terminationNoticePeriod = terminationNoticePeriod;
          contractDetailInfo.transactionAmount = transactionAmount;

          const {
            is2FARequired = false,
            isSignerTransferAllowed = false,
            needTimeStamp = false,
            isXIDRequired = false,
            isOnlyPartnerXIDRequired = false,
          } = templateDetail;

          securitiesInfo.is2FARequired = is2FARequired;
          securitiesInfo.isSignerTransferAllowed = isSignerTransferAllowed;
          securitiesInfo.needTimeStamp = needTimeStamp;
          securitiesInfo.isXIDRequired = isXIDRequired;
          securitiesInfo.isOnlyPartnerXIDRequired = isOnlyPartnerXIDRequired;
          files.value = templateDetail.contractFiles.map((file: any) => {
            const f = FileInfo.fromJson(file);
            f.inputs.forEach((input: any) => {
              input.originW = input.w;
              input.originH = input.h;
              input.originX = input.x;
              input.originY = input.y;
            });
            return f;
          });

          const { getUsersSearchUsersInAllCompanies } =
            RepositoryFactory.getRepository<TemplateRepository>(
              TemplateRepository
            );

          const userPayload = await getUsersSearchUsersInAllCompanies("");
          const userList = userPayload.data as any[];

          const signerInfos = templateDetail.contractSigners?.map(
            (signer: any) => ({
              userId: signer.userId,
              id: signer.id,
              placeHolder: signer.placeHolder,
              email: signer.signerEmail,
              firstName: signer.signerFirstName,
              lastName: signer.signerLastName,
              companyName: signer.signerCompanyName,
            })
          );

          const viewerInfos = templateDetail.contractViewers?.map((viewer) =>
            ContractViewer.fromJson(viewer)
          );

          signers.value = signerInfos
            ? signerInfos.map((signerInfo) => ({
                ...userList.find((user) => user.id == signerInfo.userId),
                placeholder: signerInfo.placeHolder,
                signerId: signerInfo.id,
                email: signerInfo.email,
                firstName: signerInfo.firstName,
                lastName: signerInfo.lastName,
                companyName: signerInfo.companyName,
              }))
            : [];

          viewers.value = viewerInfos ? viewerInfos : [];

          const folderResponse = await getListFolder({
            folderId: templateDetail.folderId,
          });
          if (folderResponse) {
            const folderPath = folderResponse.pagination.folderPath;
            const [parentFolder] = getListFolderFromPath(folderPath).slice(
              -1
            ) as any;
            if (parentFolder)
              state.breadScrumPaths = [
                {
                  text: parentFolder.name,
                  to: `/template?folderId=${parentFolder.id}`,
                },
              ];
            else
              state.breadScrumPaths = [
                { text: i18n.t("template.tableTitle"), to: "/template" },
              ];
            state.breadScrumPaths.push({
              text: contractBasicInfo.title,
              to: "#",
            });
          }
        }
        switch (route.name) {
          case "TemplateFileAndInfo":
            state.step = 0;
            break;
          case "TemplateSigner":
            state.step = 1;
            break;
          case "TemplateVars":
            state.step = 2;
            break;
          case "TemplateSummary":
            state.step = 3;
            break;
          default:
            state.step = 0;
            break;
        }
      } else if (
        state.step === 0 &&
        route.name != "TemplateFileAndInfo" &&
        route.name != "TemplateList"
      ) {
        router.push({
          name: "TemplateFileAndInfo",
        });
      }
    });

    onMounted(() => {
      setTimeout(() => {
        state.isMounted = true;
      }, 500);
    });

    const saveEditTemplate = async () => {
      const formData = new FormData();
      files.value.forEach((file, index) => {
        formData.append(`file${index + 1}`, file.data);
      });

      const templateInformation =
        TemplateInformation.prototype.toJson.call(contractDetailInfo);
      const templateJson = JSON.stringify({
        folderId: state.folderId,
        id: contractBasicInfo.id,
        isTemplate: true,
        title: contractBasicInfo.title,
        contractPartnerName: contractBasicInfo.contractPartnerName,
        isXIDRequired: securitiesInfo.isXIDRequired,
        isOnlyPartnerXIDRequired: securitiesInfo.isOnlyPartnerXIDRequired,
        is2FARequired: securitiesInfo.is2FARequired,
        isSignerTransferAllowed: securitiesInfo.isSignerTransferAllowed,
        needTimeStamp: securitiesInfo.needTimeStamp,
        contractInformation: templateInformation,
        contractSigners: signers.value?.map((signer, index) =>
          signer.placeholder
            ? {
                id: signer.signerId,
                order: index + 1,
                placeHolder: signer.placeholder,
              }
            : {
                id: signer.signerId,
                order: index + 1,
                userId: signer.id,
                SignerEmail: signer.email,
                SignerLastName: signer.lastName,
                SignerFirstName: signer.firstName,
                SignerCompanyName: signer.companyName,
                SignerAccessCode: signer.accessCode,
              }
        ),
        contractViewers: viewers.value?.map((viewer, index) => ({
          id: viewer.id,
          order: index + 1,
          userId: viewer.userId,
          ViewerEmail: viewer.email,
          ViewerLastName: viewer.lastName,
          ViewerFirstName: viewer.firstName,
          ViewerCompanyName: viewer.companyName,
          ViewerAccessCode: viewer.accessCode || "",
        })),
        contractFiles: files.value?.map((file, index) => {
          const toJson = FileInfo.prototype.toJson;
          return toJson.call(file, index);
        }),
      });
      formData.append("data", templateJson);

      const { updateContracts } =
        RepositoryFactory.getRepository<TemplateRepository>(TemplateRepository);

      await updateContracts(formData).catch((err) => {
        store.commit("notification/showErrorNotification", err.data.message);
        return;
      });

      if (state.folderId != "") {
        router.push({
          name: "TemplateList",
          params: {
            isEditTemplateSuccess: "true",
          },
          query: {
            folderId: state.folderId,
          },
        });
      } else {
        router.push({
          name: "TemplateList",
          params: {
            isEditTemplateSuccess: "true",
          },
        });
      }
    };

    const discardTempContract = async () => {
      if (state.step == 0) {
        router.push({
          name: "TemplateList",
        });
      }

      if (files.value.length > 0) {
        // Reset contractBasicInfo
        contractBasicInfo.id = null as null | string;
        contractBasicInfo.title = "";
        contractBasicInfo.contractPartnerName = "";
        // Reset contractDetailInfo
        contractDetailInfo.contractOverview = "";
        contractDetailInfo.contractConclusionDate = null as Date | null;
        contractDetailInfo.contractStartDate = null as Date | null;
        contractDetailInfo.contractEndDate = null as Date | null;
        contractDetailInfo.terminationNoticePeriod = "";
        contractDetailInfo.isAutomaticallyUpdated = "null";
        contractDetailInfo.controlNumber = "";
        contractDetailInfo.transactionAmount = 0;
        contractDetailInfo.freeText = "";
        // Reset securitiesInfo
        securitiesInfo.isXIDRequired = false;
        securitiesInfo.isOnlyPartnerXIDRequired = false;
        securitiesInfo.is2FARequired = false;
        securitiesInfo.isSignerTransferAllowed = true;
        securitiesInfo.needTimeStamp = true;
        // Reset signer
        signers.value = [] as Signer[];
        // Reset viewer
        viewers.value = [] as ContractViewer[];
        // Reset files
        files.value = [] as Array<FileInfo>;
        // Reset isDocxTemplate
        state.isDocxTemplate = false;
        // Reset step
        state.step = 0;
      }
    };

    const onDeleteContract = ({ err, res }: { err: any; res: any }) => {
      if (err) {
        store.commit("notification/showErrorNotification", err.data.message);
      } else {
        router.push({
          name: "TemplateList",
          params: { isDeleteContractSuccerss: "true" },
        });
      }
    };

    return {
      ...toRefs(state),
      progressItems,
      isEditing,
      lastStep,
      files,
      contractBasicInfo,
      contractDetailInfo,
      signers,
      securitiesInfo,
      viewers,
      saveEditTemplate,
      discardTempContract,
      onDeleteContract,
      isMobile,
      errorIndex,
      suggestTexts,
    };
  },
  methods: {
    ...mapMutations("popup", ["setPopup", "unsetPopup"]),
    next() {
      this.step++;
      if (this.step < 4) this.scrollToSectionTop();
    },
    back() {
      this.step--;
      this.scrollToSectionTop();
    },
    scrollToSectionTop() {
      const el = this.$refs["templateMainContain"] as HTMLElement;
      if (el) {
        el.scrollIntoView();
      }
    },
    handleDeleteTemplate(): void {
      this.setPopup("TemplateConfirmDelete");
    },
  },
});
