import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a99339e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup-container" }
const _hoisted_2 = { class: "text-popup" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_popup = _resolveComponent("popup")!

  return (_ctx.popupState)
    ? (_openBlock(), _createBlock(_component_popup, {
        key: 0,
        title: _ctx.$t('template.deleteTemplatePopupTitle'),
        onOnClickOutsidePopup: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isLoading ? null : _ctx.hidePopup())),
        onOnHide: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isLoading ? null : _ctx.hidePopup()))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("p", {
                innerHTML: 
            `<span class='delete_name'>${_ctx.title}</span>${_ctx.$t(
              'folder.deleteFileMessage1'
            )}`
          ,
                class: "message mb-5"
              }, null, 8, _hoisted_3),
              _createElementVNode("div", null, [
                _createVNode(_component_FlatButton, {
                  text: _ctx.$t('folder.deleteConfirmButton'),
                  class: "confirm-button",
                  onOnLoading: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isLoading = $event)),
                  "on-click": _ctx.confirm
                }, null, 8, ["text", "on-click"])
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["title"]))
    : _createCommentVNode("", true)
}